import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const BlogCard = styled.div`
  padding: 3rem 4rem;
  display: block;
`

const BlogCardTitle = styled.h2``

const BlogCardContent = styled.div`
  font-weight: 200;
`

const BlogCardDate = styled.div`
  font-size: 1rem;
  float: right;
`

export default function BlogPostCard({ post }) {
  const { title, date, path } = post.node.frontmatter
  const { excerpt } = post.node

  return (
    <Link to={path}>
      <BlogCard>
        <BlogCardTitle>{title}</BlogCardTitle>
        <BlogCardContent>{excerpt}</BlogCardContent>
        <BlogCardDate>{date}</BlogCardDate>
      </BlogCard>
    </Link>
  )
}

BlogPostCard.propTypes = {
  post: PropTypes.object
}
