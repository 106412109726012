import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import FunkyTitle from '../components/FunkyTitle'
import Section from '../css/Section'
import Content from '../css/Content'
import BlogPostCard from '../components/BlogPostCard'

const pageTitle = 'Blog Posts'

const BlogIndexPage = ({
  data: {
    allMarkdownRemark: { edges }
  }
}) => {
  return (
    <>
      <FunkyTitle title={pageTitle} />
      <Section>
        <Content>
          {edges.map((post) => (
            <BlogPostCard key={post.node.id} post={post} />
          ))}
        </Content>
      </Section>
    </>
  )
}

BlogIndexPage.propTypes = {
  data: PropTypes.object
}

BlogIndexPage.Layout = Layout
BlogIndexPage.pageTitle = pageTitle

export default BlogIndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { public: { eq: true } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            public
            path
          }
        }
      }
    }
  }
`
